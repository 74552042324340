<template>
<div>
  <el-card class="box-card">
    <el-row :gutter="10">
      <el-col :span="18">
        <div style="width: 100%;height: calc(100vh - 208px);background-color: #ddd;border-radius: 10px;overflow-y: auto;">
          <div style="width: 100%; padding: 20px;">
            <div style="padding: 20px;font-size: 32px;font-weight: 600;color: #7fa0df; text-align: center">{{item.title}}</div>
            <div class="titleBox"><span>{{item.type}}</span>  {{item.author}}</div>
            <div style="display: flex; flex-direction: column; align-items: center; padding: 10px;">
                <!--       播放文本转语音，虽然不怎么样！         -->
                <el-button icon="el-icon-video-play" @click="playVoice">TTS</el-button>
                <div v-for="it in item.content">
                <div class="subBox">{{it}}</div>
              </div>
            </div>

          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div style="width: 100%;height: calc(100vh - 208px);border: 2px solid #ddd;border-radius: 10px;">
          <div style="width: 100%;height:80px;margin: 20px;">
<!--            <el-upload :action="$baseUrl + '/ts300/import'" :show-file-list="false"-->
<!--                       :on-success="handleFileUploadSuccess" style="display: inline-block">-->
<!--              <el-button type="primary" class="ml-5">导入数据<i class="el-icon-top"></i></el-button>-->
<!--            </el-upload>-->
            <div style="width:70%; margin: 20px; padding: 10px; border: 1px solid #eee;">
              <el-radio-group v-model="radio" style="" @input="radioInput">
                  <el-radio size="medium" label="1" border>全部</el-radio>
                  <el-radio size="medium" label="2" border>作者</el-radio>
              </el-radio-group>
              <div style="min-height: 50px">
                <el-autocomplete v-if="radio === '2'" style="width: 180px;padding-top: 20px"
                     class="inline-input" clearable
                     v-model="state1"
                     :fetch-suggestions="querySearch"
                     placeholder="请输入作者关键字"
                     @input="handleSelect"
                ></el-autocomplete>
              </div>
            </div>
            <div  style="width:70%; margin: 20px; padding: 10px; border: 1px solid #eee;">
              <el-input placeholder="请输入标题关键字" prefix-icon="el-icon-search" maxlength="8" clearable
                        @input="handleInput" v-model="input" style="width: 180px">
              </el-input>
              <div style="margin: 0px;display: flex;flex-direction: column;height: 400px;overflow: auto">
                <div class="itemBox" v-for="(item, index) in listTemp" @click="itemClick(index)">
                  {{item.title}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-card>
</div>
</template>
<script>
import Speech from 'speak-tts'
    export default {
        name: 'ts300',
        data() {
          return {
            state1: '',
            radio: '1',
            item:{
              type: '',
              title: '',
              author: '',
              content: '',
            },
            list:[],      // 所有成语队列
            typeArr: [],
            authorArr: [],
            listTemp1: [], // 搜索到的供选择(总，如作者全作品，或者全部）
            listTemp: [], // 搜索到的供选择（标题关键字搜索的结果）
            input: '',
          }
        },
        mounted() {
          this.speechInit()
          this.request.get("/ts300").then(res => {
            this.list = res.data
            this.listTemp = res.data;
            this.listTemp1 = res.data;
            // console.log(this.list)
            var typeSet = new Set()
            var authorSet = new Set()
            this.list.forEach((item)=>{ // 统计类型和作者
              typeSet.add(item.type)
              authorSet.add(item.author)
            })
            this.typeArr = [...typeSet]
            // this.authorArr = [...authorSet]
            var arr = [...authorSet]
            arr.map((name)=>{
              this.authorArr.push({'value': name})
            })
            // console.log("作者：", authorSet)
            // console.log("作者：", this.authorArr)
            this.itemClick(0)
          })

        },
        methods: {
          speechInit(){
            this.speech = new Speech();
            this.speech.setLanguage('zh-CN');
            // 'voice':'Google UK English Male',
            this.speech.init().then(()=>{
              console.log('语音播报初始化完成')
            })
          },

          playVoice(){
            this.speech.speak({text: this.item.content.toString()}).then(()=>{
            // this.speech.speak({text: '微信收款10元'}).then(()=>{
              console.log("播报完成")
            })
          },

          radioInput(){
            this.listTemp = this.list;
            this.listTemp1 = this.list;
            this.input = ''
            this.state1 = ''
            this.itemClick(0)
          },
          querySearch(queryString, cb) {
            var authorArr = this.authorArr;
            var results = queryString ? authorArr.filter(this.createFilter(queryString)) : authorArr;
            // 调用 callback 返回建议列表的数据
            cb(results);
          },
          createFilter(queryString) {
            return (authorArr) => {
              return (authorArr.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
          },
          handleSelect(item) {  // 当item在this.authorArr中时，触发下列表搜索
            if(JSON.stringify(this.authorArr).indexOf(JSON.stringify(item)) !== -1){
              console.log('搜索到的作者：', item);
              this.listTemp1 = []
              this.list.map((el)=>{
                if(el.author === item){
                  this.listTemp1.push(el)
                }
              })
              this.listTemp = this.listTemp1
            }
          },

          itemClick(index){
            this.item.type = this.listTemp[index].type
            this.item.author = this.listTemp[index].author
            this.item.title = this.listTemp[index].title
            var str = this.listTemp[index].content
            // 取掉括号
            var in1 = str.indexOf('(')
            if(in1 !== -1){
              var in2 = str.indexOf(')')
              if(in2 !== -1){
                var str1 = str.substring(in1, in2+1)
                str = str.replace(str1, '')
                // console.log("RES:", str1)
              }
            }
            str = str.replaceAll("。","。{%}")
            // console.log("新串：", str)
            this.item.content = str.split("{%}")
            // console.log("新串：", this.item.content)
          },

          handleInput(){
            // 1 获取输入框的值
            // 2 检测合法性
            if(!this.input.trim()){
              this.listTemp = this.listTemp1
              return;
            }
            // 3 准备发送请求获取数据
            clearTimeout(this.TimeId);
            this.TimeId=setTimeout(() => {
              this.qsearch(this.input);
            }, 1); //延迟1毫秒？
          },
          // 发送请求获取搜索建议 数据
          async qsearch(query){
            var tempFiles=[]

            this.listTemp1.forEach((item,index,arr)=> {
              if(item.title.indexOf(query)!=-1){
                tempFiles.push(item)
              }
            })
            this.listTemp = tempFiles
          },
        }
    }
</script>
<style scoped>
/**{*/
/*  margin: 0;*/
/*  padding: 0;*/
/*  box-sizing: border-box;*/
/*}*/
.titleBox{
  text-align: center;
  padding-top: 20px;
}
.subBox{
  font-size: 28px;
  height: 48px;
  line-height: 48px;
  /*height: 100px;*/
  /*padding: 10px;*/
  /*margin: 10px;*/
  background-color: #ddd;
  overflow-y: hidden;
}
.itemBox{
  cursor: pointer;
  margin: 2px;
  padding:8px;
  background-color: orange;
}
</style>